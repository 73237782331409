:root {
  font-size: 15px;
  line-height:14px ;
}
.ChatApp,
.Bubble{
  max-width: 100vw;
}
.MessageContainer,
.Navbar,
.Message .Bubble,
.QuickReplies,
.ChatFooter {
  background-repeat: no-repeat;
  background-size: cover;
}

.ChatFooter .Composer-input {
  background: white;
  max-height: 160px;
  border: 1px solid darkgray;
  min-height: 80px;
  height: auto;
}

@media (max-width: 767px) {
  .ChatFooter .Composer-input {
    background: white;
    max-height: 80px;
    border: 1px solid darkgray;
    min-height: 40px;
    height: auto;
  }
}

.ScrollView--x .ScrollView-scroller {
  display: flex;
  margin-bottom: -1.7rem;
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 1.125rem;
}

.Bubble.text, .Bubble.typing {
    word-wrap: break-word;
    box-sizing: border-box;
    min-width: 2.5rem;
    overflow-wrap: break-word;
    padding: .75rem;
    -webkit-user-select: text;
    user-select: text;
    white-space: normal;
}

.Message.right .Bubble {
  background: #95ec69;
  border-radius: 12px;
  margin-left: 2.5rem;
}